import { useState, useEffect } from "react";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import tokenAbi from "./token.json";
import { useRefresh } from "./utils";
import whitelist from "./whitelist.json";
import freelist from "./freelist.json";

export const connectWithMetamask = async () => {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  if (accounts.length > 0) return accounts[0];
  return null;
};

export const useChainId = (isReady) => {
  const [chainId, setChainId] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const _chainId = await window.ethereum.request({ method: "eth_chainId" });
      setChainId(_chainId.replace("0x", ""));
    };

    if (isReady) {
      fetch();

      window.ethereum.on("chainChanged", (_chainId) => {
        setChainId(_chainId.replace("0x", ""));
      });
    }
  }, [isReady]);

  return chainId;
};

export const useTotalSupply = (isReady) => {
  const { fastRefresh } = useRefresh();
  const [totalSupply, setTotalSupply] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const supply = await getTokenContract().methods.totalSupply().call();
      setTotalSupply(supply);
    };

    if (isReady) fetch();
  }, [fastRefresh, isReady]);

  return totalSupply;
};

export const usePrice = (isReady) => {
  const { fastRefresh } = useRefresh();
  const [price, setPrice] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const price = await getTokenContract().methods.JAPES_PRICE().call();
      setPrice(
        new BigNumber(price).dividedBy(new BigNumber(10).pow(18)).toString()
      );
    };

    if (isReady) fetch();
  }, [fastRefresh, isReady]);

  return price;
};

export const useMaxWLMint = (isReady) => {
  const { fastRefresh } = useRefresh();
  const [maxMint, setMaxMint] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const maxMint = await getTokenContract()
        .methods.MAX_WL_JAPES_PURCHASE()
        .call();
      setMaxMint(maxMint.toString());
    };

    if (isReady) fetch();
  }, [fastRefresh, isReady]);

  return maxMint;
};

export const useMaxPLMint = (isReady) => {
  const { fastRefresh } = useRefresh();
  const [maxMint, setMaxMint] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const maxMint = await getTokenContract()
        .methods.MAX_PL_JAPES_PURCHASE()
        .call();
      setMaxMint(maxMint.toString());
    };

    if (isReady) fetch();
  }, [fastRefresh, isReady]);

  return maxMint;
};

export const useSaleState = (isReady) => {
  const { fastRefresh } = useRefresh();
  const [saleState, setSaleState] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const saleState = await getTokenContract().methods.isSaleActive().call();
      setSaleState(saleState.toString());
    };

    if (isReady) fetch();
  }, [fastRefresh, isReady]);

  return saleState;
};

export const checkIfFreeMintIncluded = (address) => {
  if (address === undefined) return {};
  let freeMintKey = Object.keys(freelist).find((acc) => {
    if (acc.toLowerCase() === address.toLowerCase()) {
      return true;
    }
    return false;
  });
  return freelist[freeMintKey];
};

export const checkIfWhitelistMintIncluded = (address) => {
  if (address === undefined) return {};
  let whiteMintKey = Object.keys(whitelist).find((acc) => {
    if (acc.toLowerCase() === address.toLowerCase()) {
      return true;
    }
    return false;
  });
  return whitelist[whiteMintKey];
};

export const useMintedAmounts = (isReady, account) => {
  const { fastRefresh } = useRefresh();
  const [mintedAmounts, setMintedAmounts] = useState(0);

  useEffect(() => {
    if (account !== undefined) {
      const fetch = async () => {
        let res = await getTokenContract().methods.balanceOf(account).call();
        setMintedAmounts(parseInt(`${res}`));
      };
      if (isReady) fetch();
    }
  }, [fastRefresh, isReady, account]);

  return mintedAmounts;
};

export const mintNFTs = async (account, count, price) => {
  if (account === undefined) return;
  let freeMintedObj = checkIfFreeMintIncluded(account);
  let whiteMintedObj = checkIfWhitelistMintIncluded(account);
  let numberMinted = await getTokenContract().methods.balanceOf(account).call();
  if (
    freeMintedObj !== undefined &&
    parseInt(`${numberMinted}`) < parseInt(`${freeMintedObj["amount"]}`)
  ) {
    try {
      let res = getTokenContract()
        .methods.freeMint(freeMintedObj["amount"], freeMintedObj["proofs"])
        .send({
          type: "0x2",
          from: account,
          value: 0,
          // maxFeePerGas: "50000000000",
          // maxPriorityFeePerGas: "2000000000",
        });
      return res;
    } catch (err) {
      return err;
    }
  }
  if (
    whiteMintedObj !== undefined &&
    parseInt(`${numberMinted}`) >=
      parseInt(
        `${freeMintedObj !== undefined ? freeMintedObj["amount"] : 0}`
      ) &&
    parseInt(`${numberMinted}`) <
      parseInt(`${freeMintedObj !== undefined ? freeMintedObj["amount"] : 0}`) +
        10
  ) {
    try {
      let res = getTokenContract()
        .methods.whitelistMint(count, whiteMintedObj["proofs"])
        .send({
          type: "0x2",
          from: account,
          value: new BigNumber(price).times(Math.pow(10, 18)).times(count),
          // maxFeePerGas: "50000000000",
          // maxPriorityFeePerGas: "2000000000",
        });
      return res;
    } catch (err) {
      return err;
    }
  }
  return getTokenContract()
    .methods.mint(count)
    .send({
      type: "0x2",
      from: account,
      value: new BigNumber(price).times(Math.pow(10, 18)).times(count),
      // maxFeePerGas: "50000000000",
      // maxPriorityFeePerGas: "2000000000",
    });
};

const getTokenContract = () => {
  const web3 = new Web3(window.ethereum); //TODO

  return new web3.eth.Contract(
    tokenAbi,
    "0xD2d6b889468E261Aa7e1586fFcECA338F1D7D104"
  );
};
