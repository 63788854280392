/* eslint-disable import/no-anonymous-default-export */
import SmoothScroll from "smooth-scroll";
import { Navigation } from "./components/navigation";
import { Intro } from "./components/intro";
// import { Roadmap } from "./components/roadmap";
// import { About } from "./components/about";
// import { Note } from "./components/note";
// import { Contributor } from "./components/contributor";

import { RefreshContextProvider } from "./data/utils";
import jsonData from "./data/data.json";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export default () => {
  return (
    <RefreshContextProvider>
      <div>
        <Navigation data={jsonData.Navigation} />
        <Intro data={jsonData.Header} />
        {/* <Roadmap />
        <About />
        <Note />
        <Contributor data={jsonData.Contributors} /> */}
      </div>
    </RefreshContextProvider>
  );
};
