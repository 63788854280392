import { useState } from "react";
import { connectWithMetamask } from "../data/contract";

export const Navigation = (props) => {
  const [account, setAccount] = useState();
  const handleConnect = async () => {
    if (account) {
      setAccount(null);
      return;
    }
    const acc = await connectWithMetamask();
    if (acc) {
      setAccount(acc);
    }
  };
  return (
    <nav className="menu navbar navbar-default navbar-fixed-top">
      <div className="container-fluid">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className="social">
            <a
              href={props.data.etherscan}
              alt="etherscan"
              target="_blank"
              rel="noreferrer"
            >
              <img src="img/etherscan.png" alt="etherscan" />
            </a>
            <a
              href={props.data.discord}
              alt="discord"
              target="_blank"
              rel="noreferrer"
            >
              <img src="img/discord.png" alt="discord" />
            </a>
            <a
              href={props.data.twitter}
              alt="twitter"
              target="_blank"
              rel="noreferrer"
            >
              <img src="img/twitter.png" alt="twitter" />
            </a>
            <a
              href={props.data.opensea}
              alt="opensea"
              target="_blank"
              rel="noreferrer"
            >
              <img src="img/opensea.png" alt="opensea" />
            </a>
            <a
              href={props.data.x2y2}
              alt="x2y2"
              target="_blank"
              rel="noreferrer"
            >
              <img src="img/x2y2.png" alt="twitter" />
            </a>
            <a
              href={props.data.looksrare}
              alt="looksrare"
              target="_blank"
              rel="noreferrer"
            >
              <img src="img/looksrare.png" alt="twitter" />
            </a>
            <button className="btn btn-lg btn-connect" onClick={handleConnect}>
              {account ? `${account.substr(0, 7) + "..."}` : "Connect Wallet"}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};
