/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { RangeStepInput } from "react-range-step-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  connectWithMetamask,
  useTotalSupply,
  useChainId,
  usePrice,
  mintNFTs,
  useMaxWLMint,
  useMaxPLMint,
  useSaleState,
  useMintedAmounts,
  checkIfFreeMintIncluded,
  checkIfWhitelistMintIncluded,
} from "../data/contract";
import { useRefresh } from "../data/utils";

const TOAST_OPTIONS = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
};
const WL_START = new Date(1659319200);
const PL_START = new Date(1659405600);
// const WL_START = new Date(1659275869);
// const PL_START = new Date(1659276949);
const TARGET_NETWORK = "1";

export const Intro = (props) => {
  const [count, setCount] = useState(20);

  const handleInput = ({ target: { value } }) => {
    let text = value.replace(/[^0-9.]/g, "").trim();
    let limit = onWlSale ? 10 : 20;
    if (text.length === 0) setCount(text);
    else setCount(Math.max(0, Math.min(limit, parseInt(text))).toString());
  };
  const [account, setAccount] = useState();
  const [onFreeSale, setFreeSale] = useState(false);
  const [onWlSale, setWlSale] = useState(false);
  const [onPlSale, setPlSale] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [noti, setNoti] = useState("");

  let freeMintObj = checkIfFreeMintIncluded(account);
  let whiteMintObj = checkIfWhitelistMintIncluded(account);

  const { timerRefresh } = useRefresh();

  const isMetamaskEnabled = typeof window.ethereum !== "undefined";
  const chainId = useChainId(isMetamaskEnabled);
  const isSaleActive = useSaleState(
    isMetamaskEnabled && chainId === TARGET_NETWORK
  );
  const totalSupply = useTotalSupply(isSaleActive);
  const maxWlMint = useMaxWLMint(isSaleActive);
  const maxPlMint = useMaxPLMint(isSaleActive);
  const price = usePrice(isSaleActive);
  const numberMinted = useMintedAmounts(isSaleActive, account);

  useEffect(() => {
    if (price.length > 0 && totalSupply.length > 0 && maxWlMint && maxPlMint)
      setLoading(false);
  }, [price, totalSupply]);

  useEffect(() => {
    let curTime = Math.floor(Date.now() / 1000);
    if (!onFreeSale && isSaleActive) setFreeSale(true);
    if (!onWlSale && isSaleActive && WL_START <= curTime) setWlSale(true);
    if (!onPlSale && isSaleActive && PL_START <= curTime) setPlSale(true);

    freeMintObj = checkIfFreeMintIncluded(account);
    whiteMintObj = checkIfWhitelistMintIncluded(account);
    let wlStartHour = Math.floor((WL_START - curTime) / 3600);
    let wlStartMin = Math.floor(((WL_START - curTime) % 3600) / 60);
    let wlStartSec = (WL_START - curTime) % 60;

    let plStartHour = Math.floor((PL_START - curTime) / 3600);
    let plStartMin = Math.floor(((PL_START - curTime) % 3600) / 60);
    let pwlStartSec = (PL_START - curTime) % 60;
    if (curTime < WL_START)
      setNoti(
        `Whitelist Mint is open in ${wlStartHour}:${wlStartMin}:${wlStartSec}`
      );
    else if (curTime >= WL_START && curTime < PL_START)
      setNoti(
        `Public Mint is open in ${plStartHour}:${plStartMin}:${pwlStartSec}`
      );
  }, [timerRefresh]);

  useEffect(() => {
    connectWithMetamask().then((acc) => {
      if (acc) setAccount(acc);
    });
  }, []);

  const handleMint = async () => {
    const acc = await connectWithMetamask();
    if (acc) {
      setAccount(acc);
      onMint(count);
    }
  };

  const onMint = (count) => {
    if (!isSaleActive) return;
    if (
      (freeMintObj !== undefined &&
        numberMinted < parseInt(`${freeMintObj["amount"]}`)) ||
      (onWlSale &&
        whiteMintObj !== undefined &&
        numberMinted <
          parseInt(`${maxWlMint}`) +
            (freeMintObj !== undefined
              ? parseInt(`${freeMintObj["amount"]}`)
              : 0)) ||
      (onPlSale &&
        numberMinted <
          parseInt(`${maxPlMint}`) +
            (whiteMintObj !== undefined ? parseInt(`${maxWlMint}`) : 0) +
            (freeMintObj !== undefined
              ? parseInt(`${freeMintObj["amount"]}`)
              : 0))
    ) {
      mintNFTs(account, count, price)
        .on("receipt", () =>
          toast.info("Wojapes minted successfully", TOAST_OPTIONS)
        )
        .on("error", () => {
          toast.error("Wojapes not minted. Errors occurred", TOAST_OPTIONS);
        });
    }
  };

  return (
    <div className="intro container-fluid">
      <div className="row">
        {/* <div className="background-container">
          <img alt="blankjape" />
        </div> */}
        <div className="col-xs-12 col-md-12">
          <div className="info-container">
            <p
              className="title"
              dangerouslySetInnerHTML={{ __html: props.data.title }}
            />
          </div>
          <div className="info-container">
            <p
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: props.data.subtitle }}
            />
          </div>
          <div className="info-container">
            <p
              className="brand"
              dangerouslySetInnerHTML={{ __html: props.data.brand }}
            />
          </div>
        </div>
        <div className="col-xs-12 col-md-12">
          <div className="info-container" style={{ justifyContent: "center" }}>
            <p className="brand">
              Dumb Wojapes are 6969 beautiful generative artworks <br /> on the
              Ethereum blockchain. Created from over 400 traits <br /> Each
              Wojape is individual and special, just like you. <br />
              Some 'japes are even showing off their unique seed phrase, <br />
              randomly generated at mint. <br /> <br />
              Dumb Wojapes are for the people. <br />
              All artwork is CC0 & in the public domain.
            </p>
          </div>
        </div>
        <div className="col-xs-12 col-md-12">
          <div className="mint-container">
            <div className="mint-info text-center">
              <p className="mint-info-max">
                {freeMintObj !== undefined &&
                numberMinted < parseInt(`${freeMintObj["amount"]}`)
                  ? `Free mint ${freeMintObj["amount"]} wojapes`
                  : whiteMintObj !== undefined &&
                    numberMinted >=
                      parseInt(
                        `${
                          freeMintObj !== undefined ? freeMintObj["amount"] : 0
                        }`
                      ) &&
                    numberMinted <
                      parseInt(
                        `${
                          freeMintObj !== undefined ? freeMintObj["amount"] : 0
                        }`
                      ) +
                        parseInt(`${maxWlMint}`)
                  ? `Max ${maxWlMint} per wallet for WL Sale(${
                      whiteMintObj !== undefined
                        ? "You are whitelisted"
                        : "You are not whitelisted"
                    })`
                  : `Max ${maxPlMint} per wallet for Public Sale`}
              </p>
              {freeMintObj !== undefined &&
              numberMinted < parseInt(`${freeMintObj["amount"]}`) ? (
                ""
              ) : (
                <div className="mint-setting">
                  <RangeStepInput
                    className="counter-slider"
                    max={
                      onWlSale &&
                      whiteMintObj &&
                      numberMinted <
                        parseInt(
                          `${
                            freeMintObj !== undefined
                              ? freeMintObj["amount"]
                              : 0
                          }`
                        ) +
                          parseInt(`${maxWlMint}`)
                        ? parseInt(
                            `${
                              freeMintObj !== undefined
                                ? freeMintObj["amount"]
                                : 0
                            }`
                          ) +
                          parseInt(`${maxWlMint}`) -
                          numberMinted
                        : onPlSale &&
                          numberMinted <
                            parseInt(
                              `${
                                freeMintObj !== undefined
                                  ? freeMintObj["amount"]
                                  : 0
                              }`
                            ) +
                              parseInt(`${maxWlMint}`) +
                              parseInt(`${maxPlMint}`)
                        ? parseInt(
                            `${
                              freeMintObj !== undefined
                                ? freeMintObj["amount"]
                                : 0
                            }`
                          ) +
                          parseInt(`${maxWlMint}`) +
                          parseInt(`${maxPlMint}`) -
                          numberMinted
                        : 20
                    }
                    min={0}
                    value={count === "" ? 0 : count}
                    step={1}
                    onChange={(e) => {
                      setCount(e.target.value);
                    }}
                  />
                  <input
                    className="counter-editor"
                    onChange={handleInput}
                    value={count}
                  />
                </div>
              )}
              <button
                onClick={handleMint}
                className={`btn btn-mint btn-lg page-scroll ${
                  isMetamaskEnabled && chainId === TARGET_NETWORK
                    ? ""
                    : "btn-disabled"
                }`}
              >
                {!isMetamaskEnabled
                  ? "Install Metamask"
                  : isMetamaskEnabled && chainId !== TARGET_NETWORK
                  ? "Wrong Network"
                  : "Mint Wojapes"}
              </button>
              <p className="info-inner-minted">
                {isLoading ? "0" : totalSupply} / 6969 minted
              </p>
              <p className="info-inner-price">
                {isLoading ? "0.00" : price} ETH per mint
              </p>
              <p className="info-inner-price" style={{ fontWeight: "bold" }}>
                {isLoading ? "" : noti}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
